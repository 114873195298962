<template>
  <div class="card card-lightblue card-outline">
    <div class="card-header pt-2 pb-2">
      <h3 class="card-title text-dark">Trabajos</h3>
      <div class="card-tools">
        <button
          type="button"
          data-card-widget="collapse"
          data-toggle="tooltip"
          title="Collapse"
          class="btn btn-tool"
        >
          <i class="fas fa-plus"></i>
        </button>
      </div>
    </div>
    <div class="card-body">
      <div
        class="row"
        v-if="
          $store.getters.can(
            'cs.revisionServiciosInternos.editTarifasTrabajos'
          ) &&
            estado == 4 &&
            (listasForms.det_tarifas.length > 0 || flagActualizaTarifa)
        "
      >
        <div class="col-md-12 text-right mb-2">
          <div class="btn-group">
            <button
              v-if="listasForms.det_tarifas.length > 0"
              class="btn btn-primary"
              @click="getFormStatus(1)"
              :disabled="flag_form_detalle != 0"
            >
              <i class="fas fa-plus"></i>
            </button>
            <button
              v-if="flagActualizaTarifa"
              class="btn bg-success"
              @click="getTarifaServicio()"
              :disabled="flag_form_detalle != 0"
            >
              <i class="fas fa-search-dollar"></i>
            </button>
          </div>
        </div>
      </div>
      <table
        class="table table-bordered table-striped table-hover table-sm mb-0"
        v-if="tarifas_servicio.length > 0 || flag_form_detalle != 0"
      >
        <thead class="thead-dark">
          <tr>
            <th class="col-1">Tipo Tiempo</th>
            <th class="col-1">Clase Tarifa</th>
            <th>Tarifa</th>
            <th class="col-2">Valor Base</th>
            <th
              class="col-1"
              v-if="
                $store.getters.can(
                  'cs.revisionServiciosInternos.editTarifasTrabajos'
                ) && estado == 4
              "
            >
              Acciones
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="flag_form_detalle == 1">
            <td class="text-center">
              <span
                class="badge"
                :class="form.nTipoTiempoTarifa ? 'badge-info' : 'badge-danger'"
              >
                {{
                  form.nTipoTiempoTarifa
                    ? form.nTipoTiempoTarifa
                    : "Sin definir"
                }}
              </span>
            </td>
            <td class="text-center">
              <span
                class="badge"
                :class="form.nClaseTarifa ? 'badge-info' : 'badge-danger'"
              >
                {{ form.nClaseTarifa ? form.nClaseTarifa : "Sin definir" }}
              </span>
            </td>
            <td>
              <v-select
                v-model="form.cs_det_tarifa"
                placeholder="Tarifa"
                label="nNombre"
                :options="listasForms.det_tarifas"
                :filterable="true"
                :class="
                  $v.form.cs_det_tarifa.$invalid ? 'is-invalid' : 'is-valid'
                "
                class="form-control form-control-sm p-0"
                @input="calcularTarifa"
              ></v-select>
            </td>
            <td class="text-right">
              {{ formatCurrency(form.valor_base) }}
            </td>
            <td class="text-right">
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-sm bg-success"
                  v-if="
                    !$v.form.$invalid &&
                      $store.getters.can(
                        'cs.revisionServiciosInternos.editTarifasTrabajos'
                      )
                  "
                  @click="save"
                >
                  <i class="fas fa-save"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-sm bg-danger"
                  @click="getFormStatus(0)"
                >
                  <i class="fas fa-ban"></i>
                </button>
              </div>
            </td>
          </tr>
          <tr v-for="(tarifa, index) in tarifas_servicio" :key="index">
            <td class="text-center">
              <span
                class="badge"
                :class="
                  getTiempoTarifa(
                    tarifa.cs_det_tarifa
                      ? tarifa.cs_det_tarifa.tarifa
                        ? tarifa.cs_det_tarifa.tarifa.tipo_tiempo_tarifa
                        : null
                      : null
                  )
                    ? 'badge-info'
                    : 'badge-danger'
                "
              >
                {{
                  getTiempoTarifa(
                    tarifa.cs_det_tarifa
                      ? tarifa.cs_det_tarifa.tarifa
                        ? tarifa.cs_det_tarifa.tarifa.tipo_tiempo_tarifa
                        : null
                      : null
                  )
                    ? getTiempoTarifa(
                        tarifa.cs_det_tarifa
                          ? tarifa.cs_det_tarifa.tarifa
                            ? tarifa.cs_det_tarifa.tarifa.tipo_tiempo_tarifa
                            : null
                          : null
                      )
                    : "Sin definir"
                }}
              </span>
            </td>
            <td class="text-center">
              <span
                class="badge"
                :class="
                  getClaseTarifa(
                    tarifa.cs_det_tarifa
                      ? tarifa.cs_det_tarifa.tarifa
                        ? tarifa.cs_det_tarifa.tarifa.clase_tarifa
                        : null
                      : null
                  )
                    ? 'bg-navy'
                    : 'badge-danger'
                "
              >
                {{
                  getClaseTarifa(
                    tarifa.cs_det_tarifa
                      ? tarifa.cs_det_tarifa.tarifa
                        ? tarifa.cs_det_tarifa.tarifa.clase_tarifa
                        : null
                      : null
                  )
                    ? getClaseTarifa(
                        tarifa.cs_det_tarifa
                          ? tarifa.cs_det_tarifa.tarifa
                            ? tarifa.cs_det_tarifa.tarifa.clase_tarifa
                            : null
                          : null
                      )
                    : "Sin definir"
                }}
              </span>
            </td>
            <td>
              {{
                tarifa.cs_det_tarifa
                  ? tarifa.cs_det_tarifa.tarifa
                    ? tarifa.cs_det_tarifa.tarifa.nombre
                    : ""
                  : "No cuenta con tarifa por Fuera de operacion más de 4 horas"
              }}
            </td>
            <td class="text-right">
              {{
                index === pos_tabla && flag_form_detalle == 2
                  ? formatCurrency(form.valor_base)
                  : formatCurrency(tarifa.valor_base)
              }}
            </td>
            <td
              class="text-right"
              v-if="
                $store.getters.can(
                  'cs.revisionServiciosInternos.editTarifasTrabajos'
                ) && estado == 4
              "
            >
              <div class="btn-group" v-if="flag_form_detalle == 0">
                <button
                  type="button"
                  class="btn btn-sm bg-navy"
                  @click="getFormStatus(2, index)"
                >
                  <i class="fas fa-edit"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-sm bg-danger"
                  @click="destroy(tarifa.id)"
                >
                  <i class="fas fa-trash"></i>
                </button>
              </div>
              <div
                class="btn-group"
                v-if="index === pos_tabla && flag_form_detalle == 2"
              >
                <button
                  type="button"
                  class="btn btn-sm bg-success"
                  v-if="!$v.form.$invalid"
                  @click="save"
                >
                  <i class="fas fa-save"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-sm bg-danger"
                  @click="getFormStatus(0)"
                >
                  <i class="fas fa-ban"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="row">
        <div class="col-md-12">
          <div class="callout callout-warning text-muted m-0">
            <h5 class="m-0">No hay tarifas de trabajo!</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";

export default {
  name: "CsRevisionTarifasTrabajos",

  components: {
    vSelect,
  },

  data() {
    return {
      servicio_id: null,
      estado: null,
      tarifas_servicio: [],
      flag_form_detalle: 0,
      cs_det_tarifa_type: "App\\CsDetTarifasTrabajos",
      pos_tabla: null,
      listasForms: {
        det_tarifas: [],
      },
      form: {},
      clasesTarifas: null,
      tiposTiemposTarifas: null,
      flagActualizaTarifa: false,
    };
  },

  validations: {
    form: {
      cs_det_tarifa: {
        required,
      },
    },
  },

  methods: {
    getIndex(servicio) {
      const me = this;
      this.servicio_id = servicio.id;
      this.estado = servicio.estado;
      this.tiposTiemposTarifas = this.$parent.$parent.tiposTiemposTarifas;
      this.clasesTarifas = this.$parent.$parent.clasesTarifas;
      this.flag_form_detalle = 0;
      this.form = {};
      this.index = null;

      // Se buscan las tarifas adicionales asignadas
      this.tarifas_servicio = servicio.tarifas.filter(
        (tarifa) => tarifa.cs_det_tarifa_type == this.cs_det_tarifa_type
      );

      //console.log(this.tarifas_servicio);

      // Se buscan las tarifas de los servicios que no son de tarifa 3 - "Movilización - Desmovilización"
      const search_servicio = this.tarifas_servicio.filter(
        (ts) => ts.cs_det_tarifa && ts.cs_det_tarifa.tarifa && ts.cs_det_tarifa.tarifa.clase_tarifa != 3
      );
      this.flagActualizaTarifa = search_servicio.length == 0 ? true : false;

      // Se buscan la tarifas asociadas al contrato de la empresa del servicio
      /** Se debe tener en cuenta que solo trae las tarifas de trabajo con clase de tarifa 3 - "Movilización - Desmovilización" */
      this.listasForms.det_tarifas = [];
      axios
        .get("/api/cs/tarifarios/tarifas/adicionales/listaDetTarifaTrabajo", {
          params: {
            clase_tarifa: [3], // Solo traer los de clase de tarifa "Movilización - Desmovilización"
            bloque_id: servicio.bloque_id,
            empresa_id: servicio.empresa_id,
            fecha_fin: servicio.fecha_fin,
            tarifas_servicio: JSON.stringify(this.tarifas_servicio),
          },
        })
        .then(async (response) => {
          this.listasForms.det_tarifas = response.data;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    formatCurrency(value) {
      // Formatea el número con separador de miles y símbolo de pesos colombianos
      return new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
      }).format(value);
    },

    getTiempoTarifa(tiempo_tarifa) {
      const result = this.tiposTiemposTarifas.find(
        (tt) => tt && tt.numeracion == tiempo_tarifa
      );
      return result ? result.descripcion : null;
    },

    getClaseTarifa(clase_tarifa) {
      const result = this.clasesTarifas.find(
        (tt) => tt && tt.numeracion == clase_tarifa
      );
      return result ? result.descripcion : null;
    },

    getFormStatus(status, index = null) {
      this.form = {};
      this.flag_form_detalle = status;
      this.pos_tabla = index;
      switch (status) {
        case 1:
          this.form = {
            id: null,
            cs_servicio_interno_id: this.$parent.servicio_id,
            cs_det_tarifa_type: this.cs_det_tarifa_type,
            cs_det_tarifa: null,
            cs_det_tarifa_id: null,
            valor_base: null,
          };
          break;
        case 2:
          if (this.tarifas_servicio[index].cs_det_tarifa_id) {
            this.form = {
              id: this.tarifas_servicio[index].id,
              cs_servicio_interno_id: this.$parent.servicio_id,
              cs_det_tarifa: {
                id: this.tarifas_servicio[index].cs_det_tarifa_id,
                tipo_tiempo_tarifa: this.tarifas_servicio[index].cs_det_tarifa
                  .tarifa.tipo_tiempo_tarifa,
                nTipoTiempoTarifa: this.getTiempoTarifa(
                  this.tarifas_servicio[index].cs_det_tarifa.tarifa
                    .tipo_tiempo_tarifa
                ),
                clase_tarifa: this.tarifas_servicio[index].cs_det_tarifa.tarifa
                  .clase_tarifa,
                nClaseTarifa: this.getClaseTarifa(
                  this.tarifas_servicio[index].cs_det_tarifa.tarifa.clase_tarifa
                ),
                nNombre: this.tarifas_servicio[index].cs_det_tarifa.tarifa
                  .nombre,
              },
              cs_det_tarifa_type: this.cs_det_tarifa_type,
              cs_det_tarifa_id: this.tarifas_servicio[index].cs_det_tarifa_id,
              valor_base: this.tarifas_servicio[index].valor_base,
            };
          } else {
            this.flag_form_detalle = 0;
            this.pos_tabla = null;
            this.$swal({
              icon: "error",
              title:
                "No cuenta con tarifa por Fuera de operacion más de 4 horas",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });
          }
          break;
        default:
          break;
      }
    },

    getTarifaServicio() {
      this.$parent.$parent.cargando = true;
      axios({
        method: "POST",
        url: "/api/cs/revision_servicios_internos/asignar_tarifas",
        data: {
          id: this.servicio_id,
        },
      })
        .then((response) => {
          this.$parent.$parent.cargando = false;
          if (response.data.msg) {
            this.$parent.$refs.closeModalDetalles.click();
            this.$parent.$parent.getIndex(this.$parent.$parent.page);
            this.$swal({
              icon: "success",
              title: "Tarifas guardadas exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          } else {
            this.$swal({
              icon: "error",
              title: "No se encontró tarifas para asignar",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch((e) => {
          this.$parent.$parent.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    calcularTarifa() {
      (this.form.cs_det_tarifa_id = null), (this.form.valor_base = null);

      if (this.form.cs_det_tarifa) {
        this.form.cs_det_tarifa_id = this.form.cs_det_tarifa.id;
        this.form.valor_base = this.form.cs_det_tarifa.valor;
      }
    },

    save() {
      if (!this.$v.form.$invalid) {
        this.$parent.$parent.cargando = true;
        axios({
          method: "POST",
          url: "/api/cs/revision_servicios_internos",
          data: this.form,
        })
          .then((response) => {
            this.$parent.$parent.cargando = false;
            if (!response.data.msg_error) {
              this.$parent.$refs.closeModalDetalles.click();
              this.$parent.$parent.getIndex(this.$parent.$parent.page);
              this.$swal({
                icon: "success",
                title: "La Tarifa Adicional se guardó exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            } else {
              this.form_tarifa.nombre = null;
              this.$swal({
                icon: "error",
                title: response.data.msg_error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
          })
          .catch((e) => {
            this.$parent.$parent.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error: " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    destroy(id) {
      this.$swal({
        title: "Está seguro de eliminar esta Tarifa?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          this.$parent.$parent.cargando = true;
          axios
            .delete("/api/cs/revision_servicios_internos/" + id)
            .then(() => {
              this.$parent.$refs.closeModalDetalles.click();
              this.$parent.$parent.getIndex(this.$parent.$parent.page);
              this.$swal({
                icon: "success",
                title: "Se eliminó la Tarifa exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            })
            .catch((e) => {
              this.$parent.$parent.cargando = false;
              this.$swal({
                icon: "error",
                title: "Ocurrió un error: " + e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.$parent.$parent.cargando = false;
            });
        }
      });
    },
  },
};
</script>
